import { StakeholderColDefFieldNamesEnum } from "../../../../../enums/AgGridColDefFieldNameEnum";
import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const ROLE_COLUMN_CONFIG: (roles: FP.Entities.IRole[]) => ISdColDef<any, any> = roles => {
  return {
    cellEditorParams: {
      field: StakeholderColDefFieldNamesEnum.Role,
      getValueLabel: (ee: any) => {
        if (!roles) return "";

        const role = roles.find(e => e.id === ee.id);

        if (!role) return ee;
        return role.name;
      },
      options: params => {
        return !!roles ? roles.filter(r => r.businessArea.id === params?.businessAreaId) : [];
      },
      allowFreeText: true,
      isPerson: false,
      valueSelector: "name",
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return items.filter((item: FP.Entities.IRole) => {
          const lowerName = `${item.name}`.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1;
        });
      }
    }
  };
};
export const ROLE_FILTER_CONFIG: ISdFilterDef = {};
