import { Navigate, Route, Routes } from "react-router-dom";
import { InsightApp } from "../apps/insight/InsightApp";
import { PermissionFields } from "../contexts/permissions/PermissionsTypes";
import I18n from "../core/localization/I18n";
import {
  CanContributeOrgRoute,
  CanEditOrganisationRoute,
  CanEditRoute,
  CanViewOrgRoute,
  CanViewRoute
} from "../core/router/Route";
import { ActionExtendedView } from "../pages/change/actions/actionExtendedView/ActionExtendedView_view";
import { UserActionsPage } from "../pages/change/dashboard/userReports/userActions/UserActions_view";
import { UserStakeholdersPage } from "../pages/change/dashboard/userReports/userStakeholders/UserStakeholders_view";
import { EditImpactGroupView } from "../pages/change/impactGroup/editImpactGroupView/EditImpactGroupView_view";
import { ImpactGroupExtendedView } from "../pages/change/impactGroup/impactGroupExtendedView/ImpactGroupExtendedView_view";
import { ImpactExtendedView } from "../pages/change/impacts/impactExtendedView/ImpactExtendedView_view";
import { ActionTypeSettings } from "../pages/change/organisations/settingsView/actionTypeSettings/ActionTypeSettings_view";
import { BusinessArea } from "../pages/change/organisations/settingsView/businessAreas/BusinessArea_view";
import { ImpactTypeSettings } from "../pages/change/organisations/settingsView/impactTypeSettings/ImpactTypeSettings_view";
import { LocationSettings } from "../pages/change/organisations/settingsView/locations/LocationsSettings_view";
import { OrganisationSettings } from "../pages/change/organisations/settingsView/organisationSettings/OrganisationSettings_view";
import { ProcessesSettings } from "../pages/change/organisations/settingsView/processesSettings/ProcessesSettings_view";
import { TrainingProgress } from "../pages/change/organisations/settingsView/trainingProgress/TrainingProgress_view";
import { RolesSettings } from "../pages/change/organisations/settingsView/rolesSettings/RolesSettings_view";
import { StakeholdersSettings } from "../pages/change/organisations/settingsView/stakeholdersSettings/StakeholdersSettings_view";
import { TagSettings } from "../pages/change/organisations/settingsView/tagSettings/TagSettings_view";
import { UsersSettings } from "../pages/change/organisations/settingsView/usersSettings/UsersSettings_view";
import { CreateProgrammeView } from "../pages/change/programmes/createProgrammeView/CreateProgrammeView_view";
import { ProgrammeLocationsView } from "../pages/change/programmes/locations/ProgrammeLocations_view";
import { ProgrammeSettings } from "../pages/change/programmes/settingsView/ProgrammeSettings_view";
import { SettingsView as ProgrammeSettingsView } from "../pages/change/programmes/settingsView/SettingsView_view";
import { MilestoneSettingsView } from "../pages/change/programmes/settingsView/milestones/MilestoneSettings_view";
import { ProgrammeView } from "../pages/change/programmes/view/View_view";
import { AddImpactGroup } from "../pages/change/projects/addImpactGroup/AddImpactGroup_view";
import { CreateProjectView } from "../pages/change/projects/createProjectView/CreateProjectView_view";
import { ImpactVisualisationsView } from "../pages/change/projects/impactVisualisationsView/ImpactVisualisationsView_view";
import { ProjectSettings } from "../pages/change/projects/settingsView/ProjectSettings_view";
import { ProjectPermission } from "../pages/change/projects/settingsView/authorisation/_projectPermissions/ProjectPermissions_view";
import { StakeholderExtendedView } from "../pages/change/stakeholders/stakeholderExtendedView/StakeholderExtendedView_view";
import { StakeholderView } from "../pages/change/stakeholders/view/View_view";
import { SettingsView } from "../pages/change/users/settingsView/SettingsView_view";
import { GeneralSettings } from "../pages/change/users/settingsView/general/General_view";
import { OrganisationsSettings } from "../pages/change/users/settingsView/organisations/Organisations_view";
import { SecuritySettings } from "../pages/change/users/settingsView/security/Security_view";
import { UserView } from "../pages/change/users/view/View_view";
import { Dashboard, OverdueActionsContent } from "../pages/insight/dashboard/Dashboard_view";
import { ActionsPage } from "../pages/insight/dashboard/actions/Actions_page";
import { ImpactsPage } from "../pages/insight/dashboard/impacts/Impacts_page";
import { CoreData } from "../pages/insight/organisations/coreData/CoreData_view";
import { DashboardCapacity } from "../pages/insight/organisations/dashboard/Capacity/DashboardCapacity_view";
import { Heatmap } from "../pages/insight/organisations/dashboard/Heatmap/Heatmap_view";
import { OrganisationDashboardTabsContent } from "../pages/insight/organisations/dashboard/OrganisationDashboardTabsContent";
import { DashboardSunburstContent } from "../pages/insight/organisations/dashboard/Sunburst/Sunburst_view";
import { DashboardTimeline } from "../pages/insight/organisations/dashboard/Timeline/DashboardTimeline_view";
import { OrganisationSettingsWrapper } from "../pages/insight/organisations/settings/OrganisationSettings_view";
import { AdminSettings } from "../pages/insight/organisations/settings/adminSettings/AdminSettings_view";
import { ProgrammeSettingsWrapper } from "../pages/insight/programmes/settings/ProgrammeSettings_view";
import { ActionListView } from "../pages/insight/projects/Actions/ActionListView/ActionListView_view";
import { ActionReportingView } from "../pages/insight/projects/Actions/ActionReportingView/ActionReportingView_view";
import { ActionBreakdownView } from "../pages/insight/projects/Actions/ActionSummary/ActionSummary_view";
import { ActionTabContent } from "../pages/insight/projects/Actions/ActionTabContent";
import { AudienceView } from "../pages/insight/projects/AudiencesView/AudienceListView/AudienceListView_view";
import { AudienceMap } from "../pages/insight/projects/AudiencesView/AudienceMap/AudienceMap_view";
import { AudienceTabContent } from "../pages/insight/projects/AudiencesView/AudienceTabContent";
import { ImpactAssesment } from "../pages/insight/projects/ImpactsView/ImpactAssessment/ImpactAssessment_view";
import { ImpactGroupsReporting } from "../pages/insight/projects/ImpactsView/ImpactGroupsReporting/ImpactGroupsReporting_view";
import { ImpactGroupsView } from "../pages/insight/projects/ImpactsView/ImpactGroupsView/ImpactGroupsView_view";
import { ImpactTabContent } from "../pages/insight/projects/ImpactsView/ImpactTabContent";
import { ProcessesView } from "../pages/insight/projects/Processes/ProcessListView/ProcessesView_view";
import { ProcessReportingView } from "../pages/insight/projects/Processes/ProcessReportingView/ProcessReportingView_view";
import { ProcessTabContent } from "../pages/insight/projects/Processes/ProcessTabContent";
import { ProjectJobRoleView } from "../pages/insight/projects/Processes/ProjectJobRoleView/ProjectJobRoleView_view";
import { BenefitSettings } from "../pages/insight/projects/Settings/BenefitSettings/BenefitSettings_view";
import { MilestoneSettings } from "../pages/insight/projects/Settings/MilestoneSettings/MilestoneSettings_view";
import { ProjectSettingsWrapper } from "../pages/insight/projects/Settings/ProjectSettings_view";
import { StakeholderReporting } from "../pages/insight/projects/Stakeholders/StakeholderReporting/StakeholderReporting_view";
import { StakeholderTabContent } from "../pages/insight/projects/Stakeholders/StakeholderTabContent";
import { StakeholdersView } from "../pages/insight/projects/Stakeholders/StakeholdersListView/StakeholdersView_view";
import { ProjectView } from "../pages/insight/projects/view/ProjectView_view";
import { PageTitle } from "../services/local/pageHeader/PageHeader";
import Pages from "./InsightRoutes";
import { NavigateToCurrentMyDashboard, NavigateToCurrentOrg } from "./Redirects";
import { WrapperImpactGridWithDataLoader } from "../core/grids/builder/directorBuilder/grids/ImpactsGrid/WrapperImpactsGridWithDataLoader_view";
import { ProjectPhaseSettings } from "../pages/change/projects/settingsView/phases/ProjectPhases_view";
import { ReadinessTrackingView } from "../pages/insight/projects/AudiencesView/ReadinessTrackingView/ReadinessTrackingView_view";
import { ReadinessChartsView } from "../pages/insight/projects/AudiencesView/ReadinessChartsView/ReadinessChartsView_view";
import { TrainingTabContent } from "../pages/insight/projects/TrainingView/TrainingTabContent";
import { TrainingModulesListView } from "../pages/insight/projects/TrainingView/TrainingModulesListView/TrainingModulesListView_view";
import { TrainingTypeSettings } from "../pages/change/organisations/settingsView/trainingTypeSettings/TrainingTypeSettings_view";
import LoginComplete from "../pages/insight/users/loginComplete/LoginComplete_view";
import { TrainingCoursesListView } from "../pages/insight/projects/TrainingView/TrainingCoursesListView/TrainingCoursesListView_view";

export const InsightRoutes: React.FC<{}> = () => (
  <Routes>
    <Route path={Pages.users.auth.loginComplete.path} element={<LoginComplete />} />
    <Route path="/" element={<InsightApp />}>
      <Route index element={<Navigate to={Pages.dashboards.index.path} replace />} />
      <Route path={Pages.dashboards.index.path}>
        <Route
          index
          element={
            <>
              <PageTitle title={I18n.t("phrases.myDashboard")} />
              <Dashboard />
            </>
          }
        />
        <Route path={Pages.dashboards.impacts.path} element={<ImpactsPage />} />
        <Route path={Pages.dashboards.actions.path} element={<ActionsPage />} />
        <Route path={Pages.dashboards.userStakeholders.path} element={<UserStakeholdersPage />} />
        <Route path={Pages.dashboards.overdueActions.path} element={<OverdueActionsContent />} />
        <Route path={Pages.dashboards.upcomingActions.path} element={<UserActionsPage />} />
      </Route>
      <Route path="/takemeto/current-organisation" element={<NavigateToCurrentOrg />} />
      <Route path={Pages.baseRoute.path}>
        <Route index element={<NavigateToCurrentMyDashboard />} />
        <Route
          path={Pages.programmes.index.path}
          element={
            <>
              <PageTitle title={I18n.t(`phrases.programme`)} />
              <ProgrammeView />
            </>
          }
        />
        <Route
          path={Pages.programmes.settingsView.page.path}
          element={
            <>
              <PageTitle title={`${I18n.t(`phrases.programme`)} | ${I18n.t(`phrases.settings`)}`} />
              <ProgrammeSettingsView />
            </>
          }
        />
        <Route
          path={Pages.programmes.settingsView.index.path}
          element={
            <>
              <PageTitle title={`${I18n.t(`phrases.programme`)} | ${I18n.t(`phrases.settings`)}`} />
              <ProgrammeSettingsView />
            </>
          }
        />

        <Route
          path={Pages.projects.index.path}
          element={
            <CanViewRoute field={PermissionFields.PROJECTS}>
              <PageTitle title={I18n.t(`phrases.project`)} />
              <ProjectView />
            </CanViewRoute>
          }
        />
        <Route element={<CoreData />}>
          <Route index path={Pages.organisations.coreData.index.path} element={<BusinessArea />} />
          <Route path={Pages.organisations.coreData.locations.path} element={<LocationSettings />} />
          <Route path={Pages.organisations.coreData.roles.path} element={<RolesSettings />} />
          <Route path={Pages.organisations.coreData.processes.path} element={<ProcessesSettings />} />
          <Route path={Pages.organisations.coreData.trainingProgress.path} element={<TrainingProgress />} />
          <Route path={Pages.organisations.coreData.actionTypes.path} element={<ActionTypeSettings />} />
          <Route path={Pages.organisations.coreData.impactTypes.path} element={<ImpactTypeSettings />} />
          <Route path={Pages.organisations.coreData.labels.path} element={<TagSettings />} />
          <Route path={Pages.organisations.coreData.trainingTypes.path} element={<TrainingTypeSettings />} />
          <Route path={Pages.organisations.coreData.stakeholders.path} element={<StakeholdersSettings />} />
        </Route>

        <Route
          element={
            <CanEditOrganisationRoute>
              <OrganisationSettingsWrapper />
            </CanEditOrganisationRoute>
          }
        >
          <Route index path={Pages.organisations.settings.index.path} element={<OrganisationSettings />} />
          <Route path={Pages.organisations.settings.users.path} element={<UsersSettings />} />
          <Route path={Pages.organisations.settings.admin.path} element={<AdminSettings />} />
        </Route>
        <Route element={<ProgrammeSettingsWrapper />}>
          <Route index path={Pages.programmes.settings.index.path} element={<ProgrammeSettings />} />
          <Route path={Pages.programmes.settings.milestones.path} element={<MilestoneSettingsView />} />
        </Route>

        <Route element={<ProjectSettingsWrapper />}>
          <Route index path={Pages.projects.settings.index.path} element={<ProjectSettings />} />
          <Route path={Pages.projects.settings.milestones.path} element={<MilestoneSettings />} />
          <Route path={Pages.projects.settings.benefits.path} element={<BenefitSettings />} />
          <Route path={Pages.projects.settings.phases.path} element={<ProjectPhaseSettings />} />
          <Route path={Pages.projects.settings.permissions.path} element={<ProjectPermission />} />
        </Route>

        <Route element={<OrganisationDashboardTabsContent />}>
          <Route
            path={Pages.organisations.visualisations.heatmap.path}
            element={
              <CanViewOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <Heatmap />
              </CanViewOrgRoute>
            }
          />
          <Route
            path={Pages.organisations.visualisations.sunburst.path}
            element={
              <CanViewOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <DashboardSunburstContent />
              </CanViewOrgRoute>
            }
          />
          <Route
            path={Pages.organisations.visualisations.timeline.path}
            element={
              <CanViewOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <DashboardTimeline />
              </CanViewOrgRoute>
            }
          />
          <Route
            path={Pages.organisations.visualisations.capacity.path}
            element={
              <CanViewOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <DashboardCapacity />
              </CanViewOrgRoute>
            }
          />
          <Route
            path={Pages.projects.organisationVisualisations.sunburst.path}
            element={
              <CanViewOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <DashboardSunburstContent />
              </CanViewOrgRoute>
            }
          />

          <Route
            path={Pages.projects.organisationVisualisations.timeline.path}
            element={
              <CanViewOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <DashboardTimeline />
              </CanViewOrgRoute>
            }
          />

          <Route
            path={Pages.projects.organisationVisualisations.capacity.path}
            element={
              <CanContributeOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <DashboardCapacity />
              </CanContributeOrgRoute>
            }
          />

          <Route
            path={Pages.projects.organisationVisualisations.heatmap.path}
            element={
              <CanViewOrgRoute>
                <PageTitle title={I18n.t(`phrases.organisation`)} />
                <Heatmap />
              </CanViewOrgRoute>
            }
          />
        </Route>

        <Route
          path={Pages.organisations.createProgramme.path}
          element={
            <CanContributeOrgRoute>
              <PageTitle title={I18n.t(`phrases.programmes`)} />
              <CreateProgrammeView />
            </CanContributeOrgRoute>
          }
        />
        <Route
          path={Pages.organisations.createProject.path}
          element={
            <CanContributeOrgRoute>
              <PageTitle title={`${I18n.t("phrases.project")}`} />
              <CreateProjectView />
            </CanContributeOrgRoute>
          }
        />
        <Route
          element={
            <CanViewRoute field={PermissionFields.STAKEHOLDERS}>
              <StakeholderTabContent />
            </CanViewRoute>
          }
        >
          <Route
            path={Pages.projects.stakeholders.listView.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
                <StakeholdersView />
              </>
            }
          />

          <Route
            path={Pages.projects.stakeholders.visualisations.parameters.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
                <StakeholderReporting />
              </>
            }
          />

          <Route
            path={Pages.projects.stakeholders.visualisations.parameters.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
                <StakeholderReporting />
              </>
            }
          />
        </Route>
        <Route
          element={
            <CanViewRoute field={PermissionFields.STAKEHOLDERS}>
              <AudienceTabContent />
            </CanViewRoute>
          }
        >
          <Route
            path={Pages.projects.audiences.listView.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.audiences`)}`} />
                <AudienceView />
              </>
            }
          />

          <Route
            path={Pages.projects.audiences.readinessTrackingView.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.audiences`)}`} />
                <ReadinessTrackingView />
              </>
            }
          />

          <Route
            path={Pages.projects.audiences.readinessChartsView.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.audiences`)}`} />
                <ReadinessChartsView />
              </>
            }
          />

          <Route
            path={Pages.projects.audiences.visualisations.index.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.audiences`)}`} />
                <AudienceMap />
              </>
            }
          />
          <Route
            path={Pages.projects.audiences.visualisations.params.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.audiences`)}`} />
                <AudienceMap />
              </>
            }
          />
        </Route>
        <Route
          path={Pages.projects.stakeholders.extendedView.path}
          element={
            <CanViewRoute field={PermissionFields.STAKEHOLDERS}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderExtendedView />
            </CanViewRoute>
          }
        />

        <Route
          path={Pages.projects.stakeholders.visualisations.index.path}
          element={
            <CanViewRoute field={PermissionFields.STAKEHOLDERS}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderReporting />
            </CanViewRoute>
          }
        />
        <Route
          path={Pages.projects.actions.extendedView.path}
          element={
            <CanViewRoute field={PermissionFields.ACTIONS}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
              <ActionExtendedView />
            </CanViewRoute>
          }
        />

        <Route
          element={
            <CanViewRoute field={PermissionFields.ACTIONS}>
              <ActionTabContent />
            </CanViewRoute>
          }
        >
          <Route index path={Pages.projects.actions.listView.path} element={<ActionListView />} />
          <Route path={Pages.projects.actions.visualisations.breakdown.path} element={<ActionBreakdownView />} />
          <Route path={Pages.projects.actions.visualisations.index.path} element={<ActionReportingView />} />
        </Route>
        <Route
          path={Pages.projects.actions.visualisations.parameters.path}
          element={
            <CanViewRoute field={PermissionFields.ACTIONS}>
              <PageTitle
                title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actionVisualisationsPageHeading`)}`}
              />
              <ActionReportingView />
            </CanViewRoute>
          }
        />

        <Route
          path={Pages.projects.programmes.index.path}
          element={
            <>
              <PageTitle title={I18n.t(`phrases.programme`)} />
              <ProgrammeView />
            </>
          }
        />
        <Route
          path={Pages.projects.programmes.locations.path}
          element={
            <>
              <PageTitle title={I18n.t(`phrases.programmeLocations`)} />
              <ProgrammeLocationsView />
            </>
          }
        />

        <Route element={<ImpactTabContent />}>
          <Route
            index
            path={Pages.projects.impactGroups.listView.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.highLevelImpacts`)}`} />
                <ImpactGroupsView />
              </CanViewRoute>
            }
          />
          <Route
            index
            path={Pages.projects.impactGroups.visualisations.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.highLevelImpacts`)}`} />
                <ImpactGroupsReporting />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.impactGroups.editView.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactHighLevelImpacts`)}`} />
                <EditImpactGroupView />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.impactGroups.createView.path}
            element={
              <CanEditRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.highLevelImpacts`)}`} />
                <AddImpactGroup />
              </CanEditRoute>
            }
          />

          <Route
            path={Pages.projects.impactGroups.extendedView.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.highLevelImpacts`)}`} />
                <ImpactGroupExtendedView />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.impacts.visualisations.impactAssesment.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactAssessmentPageHeading`)}`} />
                <ImpactAssesment />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.impacts.extendedView.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
                <ImpactExtendedView />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.impacts.listView.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
                <WrapperImpactGridWithDataLoader
                  selectedLinkItems={[]}
                  onAssignSuccess={undefined}
                  gridToolbarType={"base"}
                />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.impacts.visualisations.parameters.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle
                  title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactVisualisationPageHeading`)}`}
                />
                <ImpactVisualisationsView />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.impacts.visualisations.index.path}
            element={
              <CanViewRoute field={PermissionFields.IMPACTS}>
                <PageTitle
                  title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactVisualisationPageHeading`)}`}
                />
                <ImpactVisualisationsView />
              </CanViewRoute>
            }
          />
        </Route>

        {/* Training */}

        <Route element={<TrainingTabContent />}>
          <Route
            path={Pages.projects.training.index.path}
            element={
              <CanViewRoute field={PermissionFields.PROJECTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.trainingModules`)}`} />
                <TrainingModulesListView />
              </CanViewRoute>
            }
          />
          <Route
            path={Pages.projects.training.coursesView.path}
            element={
              <CanViewRoute field={PermissionFields.PROJECTS}>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.trainingCourses`)}`} />
                <TrainingCoursesListView />
              </CanViewRoute>
            }
          />
        </Route>
        {/* Processes */}

        <Route
          element={
            <CanViewRoute field={PermissionFields.IMPACTS}>
              <ProcessTabContent />
            </CanViewRoute>
          }
        >
          <Route
            index
            path={Pages.projects.processes.listView.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.processes`)}`} />
                <ProcessesView />
              </>
            }
          />
          <Route
            index
            path={Pages.projects.processes.reports.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.processesReporting`)}`} />
                <ProcessReportingView />
              </>
            }
          />
          <Route
            index
            path={Pages.projects.processes.processRoleMapping.path}
            element={
              <>
                <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.processesMapping`)}`} />
                <ProjectJobRoleView />
              </>
            }
          />
        </Route>

        {/* -------- */}
        <Route
          path={Pages.organisations.users.path}
          element={
            <CanViewOrgRoute>
              <PageTitle title={I18n.t(`phrases.user`)} />
              <UserView />
            </CanViewOrgRoute>
          }
        />

        <Route
          path={Pages.organisations.stakeholders.path}
          element={
            <CanViewOrgRoute>
              <PageTitle title={`${I18n.t(`phrases.organisation`)} | ${I18n.t(`phrases.stakeholder`)}`} />
              <StakeholderView />
            </CanViewOrgRoute>
          }
        />

        <Route element={<SettingsView />}>
          <Route
            path={Pages.users.settings.index.path}
            index
            element={
              <>
                <PageTitle title={I18n.t(`phrases.user`)} />
                <GeneralSettings />
              </>
            }
          />
          <Route
            path={Pages.users.settings.password.path}
            element={
              <>
                <PageTitle title={I18n.t(`phrases.user`)} />
                <SecuritySettings />
              </>
            }
          />
          <Route
            path={Pages.users.settings.organisations.path}
            element={
              <>
                <PageTitle title={I18n.t(`phrases.user`)} />
                <OrganisationsSettings />
              </>
            }
          />
          <Route
            path={Pages.users.userSettings.path}
            element={
              <>
                <PageTitle title={I18n.t(`phrases.user`)} />
                <UserView />
              </>
            }
          />
        </Route>
      </Route>
    </Route>
  </Routes>
);
