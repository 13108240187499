import { useLocation, useParams } from "react-router-dom";
import {
  InsightTabContent,
  InsightTabContentItem
} from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import I18n from "../../../../core/localization/I18n";
import Pages from "../../../../routes/InsightRoutes";
import { useCurrentOrganisationId } from "../../../../services/local/organisationContext/OrganisationContextModel";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";

export const TrainingTabContent: React.FC = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const location = useLocation();
  const currentPath = location.pathname;
  const [currIndex, setCurrIndex] = useState(
    currentPath.endsWith("training") ? 0 : currentPath.endsWith("courses") ? 1 : 0
  );

  const items: InsightTabContentItem[] = useMemo(() => {
    let items = [
      {
        label: <h2 className="mb-0">{I18n.t("table.trainingModules")}</h2>,
        url: Pages.projects.training.index.generateLink(organisationId, +projectId)
      },
      {
        label: <h2 className="mb-0">{I18n.t("table.trainingCourses")}</h2>,
        url: Pages.projects.training.coursesView.generateLink(organisationId, +projectId)
      }
    ];
    return items;
    // eslint-disable-next-line
  }, [isVisualisationsEnabled, organisationId, projectId]);

  useEffect(() => {
    const pathnameParts = location.pathname.split("/").filter(e => e !== "");
    const lastPart = pathnameParts[pathnameParts.length - 1];

    if (lastPart === "training") {
      setCurrIndex(0);
    }
    if (lastPart === "courses") {
      setCurrIndex(1);
    }
  }, [location, isVisualisationsEnabled]);

  return <InsightTabContent items={items} currentIndex={currIndex} />;
});
