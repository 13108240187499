import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { IF } from "../../../../../../components/hoc/If";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { AppGrid } from "../base/AppGrid_view";
import { ModalGridToolbar } from "../base/ModalGridToolbar_view";
import { TrainingTypesGridModel } from "./TrainingTypesGridView_model";

interface TrainingTypesGridProps {
  data: any[];
  isLoading: boolean;
  gridToolbarType?: AppGridToolbarType;
  onAssignSuccess?: (selectedItems: number[]) => void;
  selectedLinkItems?: number[];
  refreshDataHandler?: any;
  onFieldUpdate?: () => void;
  preSelectedItemIds?: number[];
}

export const TrainingTypesGrid: React.FunctionComponent<TrainingTypesGridProps> = observer(
  ({
    data,
    gridToolbarType = "base",
    onAssignSuccess,
    refreshDataHandler,
    onFieldUpdate,
    preSelectedItemIds,
    isLoading
  }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const gridRef = useRef<AppGrid>();

    const [gridModel, setGridModel] = useState(
      () => new TrainingTypesGridModel(organisationId, authUser, refreshDataHandler, onFieldUpdate)
    );

    useEffect(() => {
      if (gridModel.organisationId !== organisationId) {
        setGridModel(new TrainingTypesGridModel(organisationId, authUser, refreshDataHandler, onFieldUpdate));
      }
      // eslint-disable-next-line
    }, [organisationId]);

    useEffect(() => {
      gridModel.onMount();
      return gridModel.onUnmount;
      // eslint-disable-next-line
    }, []);

    return (
      <div className="insight-tab-content__space-filler h-100 ">
        <IF condition={!data && isLoading}>
          <PositionedSpinner />
        </IF>
        <IF condition={!!data && !isLoading}>
          <IF condition={gridToolbarType === "all" || gridToolbarType === "link-modal"}>
            <ModalGridToolbar
              className="training-type-grid-modal"
              onQuichSearchInputChange={e => {
                gridRef?.current?.setSearchText(e.currentTarget.value);
              }}
              selectedItems={gridModel.selectedItems}
              preSelectedItemIds={preSelectedItemIds}
              onAssignSuccess={onAssignSuccess}
            />
          </IF>
          <IF
            condition={
              gridModel.canEditCoreData &&
              (gridToolbarType === "all" || (gridToolbarType === "base" && !!gridModel.gridActions))
            }
          >
            <AppGridToolbar
              onQuichSearchInputChange={e => {
                gridRef.current.setSearchText(e.currentTarget.value);
              }}
              selectedItemCount={gridModel.selectedItems?.length}
              actions={gridModel.gridActions}
            />
          </IF>
          <AppGrid
            ref={gridRef}
            filterStoreAddon={gridModel.filterStoreAddon}
            columnStateAddon={gridModel.columnStateAddon}
            textWrapperAddon={gridModel.textWrapAddon}
            rowData={data}
            columnDefs={gridModel.gridColumns}
            onGridStateUpdate={gridModel.onGridStateUpdate}
            shouldTrackSelectedCell={true}
          />
        </IF>
      </div>
    );
  }
);
