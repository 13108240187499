import { observer } from "mobx-react";
import * as React from "react";
import { Async } from "react-async";
import { Button } from "../../../../../../../../components/ui/Button";
import { PositionedSpinner } from "../../../../../../../../components/ui/PositionedSpinner";
import { CanEdit } from "../../../../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../../../../contexts/permissions/PermissionsTypes";
import { useFlightPathUser } from "../../../../../../../auth_insight/UserContext";
import I18n from "../../../../../../../localization/I18n";
import { useModalContext } from "../../../../../../../modalZ/context/ModalContext";
import TrainingCoursesApi from "../../../../../../../../services/api/v2/trainingCourses/TrainingCourses.api";
import { useCurrentOrganisationId } from "../../../../../../../../services/local/organisationContext/OrganisationContextModel";
import { CommentListView } from "../../../../../../../../pages/change/comments/commentListView/CommentListView_view";
import { CommentListViewModel } from "../../../../../../../../pages/change/comments/commentListView/CommentListView_model";

export interface TrainingCourseNotesSidebarModel {
  projectId: number;
  itemId: number;
}

export const TrainingCourseNotesSidebar: React.FunctionComponent<TrainingCourseNotesSidebarModel> = observer(p => {
  let [, setTrainingCourse] = React.useState(null);
  const authUser = useFlightPathUser();
  const projectId = p.projectId;
  const itemId = p.itemId;
  const modalService = useModalContext();
  const organisationId = useCurrentOrganisationId();
  const trainingCoursesProvider = TrainingCoursesApi;

  const listViewConfig = React.useMemo(() => {
    return {
      searchAttribute: "trainingCourseId",
      id: -1,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.trainingCourseNotePlaceholder")
    };
  }, [projectId, organisationId]);

  const [commentViewModel] = React.useState(() => new CommentListViewModel(projectId, authUser, listViewConfig));

  const load = React.useCallback(async () => {
    const res = await trainingCoursesProvider.getDetailedById(organisationId, projectId, itemId);

    if (!res || res.isError) {
      return;
    }

    setTrainingCourse(res.payload);
    commentViewModel.setConfig({
      id: p.itemId
    });
    // eslint-disable-next-line
  }, [itemId, projectId, organisationId, commentViewModel, trainingCoursesProvider]);
  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid trainingCourse-compact-view">
            <CanEdit projectId={projectId} field={PermissionFields.PROJECTS}>
              <div className="row mb-0">
                <div className="col">
                  <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
                </div>
              </div>
            </CanEdit>
            <CommentListView model={commentViewModel} field={PermissionFields.PROJECTS} />
            <div className="row mb-5">
              <div className="col">
                <div className="d-flex">
                  <Button
                    className="ml-auto"
                    onClick={() => {
                      modalService.hide();
                    }}
                  >
                    {I18n.t("phrases.done")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
