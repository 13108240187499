import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { IF } from "../../../../../../components/hoc/If";
import { useCurrentOrganisationId } from "../../../../../../services/local/organisationContext/OrganisationContextModel";
import { useFlightPathUser } from "../../../../../auth_insight";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { AppGridToolbar } from "../base/AppGridToolbar_view";
import { AppGrid } from "../base/AppGrid_view";
import { ModalGridToolbar } from "../base/ModalGridToolbar_view";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { EntityTypes, GridTypes } from "../../../../../../enums";
import { TrainingCoursesGridModel } from "./TrainingCoursesGridView_model";

interface TrainingCoursesGridProps {
  gridToolbarType?: AppGridToolbarType;
  onAssignSuccess?: (selectedItems: number[]) => void;
  isLoading: boolean;
  data: FP.Entities.ITrainingCourseSummary[];
  connectedUsers?: FP.Entities.IUser[];
  selectedItemIds?: number[];
  onCellStateChange?: (
    organisationId: number,
    projectId: number
  ) => (cell: string, isEditMode?: boolean) => Promise<void>;
  preSelectedItemIds?: number[];
  gridType?: EntityTypes | GridTypes;
  onFieldUpdate: () => void;
  refreshDataHandler?: any;
}

export const TrainingCoursesGrid: React.FunctionComponent<TrainingCoursesGridProps> = observer(
  ({
    gridToolbarType = "base",
    onAssignSuccess,
    data,
    connectedUsers,
    onCellStateChange,
    isLoading,
    preSelectedItemIds,
    gridType,
    onFieldUpdate,
    refreshDataHandler
  }) => {
    const organisationId = useCurrentOrganisationId();
    const authUser = useFlightPathUser();
    const [searchParams] = useSearchParams();

    const gridRef = useRef<AppGrid>();
    const { projectId } = useParams<{ projectId: string }>();

    const [gridModel, setGridModel] = useState(
      () =>
        new TrainingCoursesGridModel(
          organisationId,
          +projectId,
          authUser,
          searchParams,
          gridToolbarType,
          preSelectedItemIds,
          gridType,
          onFieldUpdate,
          refreshDataHandler
        )
    );

    useEffect(() => {
      if (gridModel.projectId !== +projectId || gridModel.organisationId !== organisationId) {
        setGridModel(
          new TrainingCoursesGridModel(
            organisationId,
            +projectId,
            authUser,
            searchParams,
            gridToolbarType,
            preSelectedItemIds,
            gridType,
            onFieldUpdate,
            refreshDataHandler
          )
        );
      }
      // eslint-disable-next-line
    }, [organisationId, projectId]);

    useEffect(() => {
      gridModel.onMount();
      return gridModel.onUnmount;
      // eslint-disable-next-line
    }, []);

    return (
      <>
        <IF condition={isLoading}>
          <PositionedSpinner />
        </IF>
        <IF condition={!isLoading}>
          <IF condition={gridToolbarType === "all" || gridToolbarType === "link-modal"}>
            <ModalGridToolbar
              className="training-courses-action-grid"
              onQuichSearchInputChange={e => {
                gridRef?.current?.setSearchText(e.currentTarget.value);
              }}
              selectedItems={gridModel.selectedItems}
              preSelectedItemIds={preSelectedItemIds}
              onAssignSuccess={onAssignSuccess}
            />
          </IF>
          <IF
            condition={
              gridModel.userCanEditTrainingCourses && (gridToolbarType === "all" || gridToolbarType === "base")
            }
          >
            <AppGridToolbar
              connectedUsers={connectedUsers}
              onQuichSearchInputChange={e => {
                gridRef.current.setSearchText(e.currentTarget.value);
              }}
              selectedItemCount={gridModel.selectedItems?.length}
              actions={gridModel.gridActions}
            />
          </IF>
          <AppGrid
            ref={gridRef}
            gridOptions={{ context: { deleteFn: gridModel.deleteFieldData } }}
            filterStoreAddon={gridModel.filterStoreAddon}
            columnStateAddon={gridModel.columnStateAddon}
            textWrapperAddon={gridModel.textWrapAddon}
            onGridStateUpdate={gridModel.onGridStateUpdate}
            rowData={data}
            columnDefs={gridModel.gridColumns}
            onFirstData={gridModel.setSelectedNodes}
            shouldTrackSelectedCell={true}
            connectedUsers={connectedUsers}
            onCellClicked={onCellStateChange && onCellStateChange(organisationId, +projectId)}
            onCellEditModeChange={onCellStateChange && onCellStateChange(organisationId, +projectId)}
            rowSelection="multiple" // Options - allows click selection of rows
          />
        </IF>
      </>
    );
  }
);
