import { LegendProps } from "@nivo/legends/dist/types/types";
import { ResponsiveRadar } from "@nivo/radar";
import React from "react";
import I18n from "../../../core/localization/I18n";

interface IRadarMargin {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface IRadarDataObject extends Record<string, unknown> {
  id: string;
  label: string;
  value: number;
  color: string;
}

export interface RadarChartProps {
  data: IRadarDataObject[];
  maxValue: number;
  margin?: IRadarMargin;
  keys?: any;
  hasLegend?: boolean;
  translateLegendX?: number;
  translateLegendY?: number;
  colors?: string[];
  tooltipFn?: (datum: any) => JSX.Element | null;
  onClickFn?: (datum: any) => void;
  isAnimated?: boolean;
}

const getRadarMargin = (margin: IRadarMargin) => {
  const defaultMargin = { top: 70, right: 100, bottom: 40, left: 100 };
  if (!!margin) return margin;
  return defaultMargin;
};

const getTranslateX = (translateX: number) => {
  const defaultTranslateX = 0;
  if (translateX !== undefined) return translateX;
  return defaultTranslateX;
};

const getTranslateY = (translateY: number) => {
  const defaultTranslateY = 0;
  if (translateY !== undefined) return translateY;
  return defaultTranslateY;
};

const getLegend = (hasLegend: boolean, translateLegendX: number, translateLegendY: number) => {
  if (!hasLegend) return [];

  const legend = {} as LegendProps;
  legend.anchor = "top-left";
  legend.direction = "column";
  legend.translateX = getTranslateX(translateLegendX);
  legend.translateY = getTranslateY(translateLegendY);
  legend.itemWidth = 80;
  legend.itemHeight = 20;
  legend.itemTextColor = "#999";
  legend.symbolSize = 12;
  legend.symbolShape = "circle";
  legend.data = [
    {
      id: "targetValues",
      label: I18n.t("phrases.targetValues")
    },
    {
      id: "latestValues",
      label: I18n.t("phrases.latestValues")
    }
  ];
  legend.effects = [
    {
      on: "hover",
      style: {
        itemTextColor: "#000"
      }
    }
  ];

  return [legend];
};

const animatedRadar = (isAnimatedProp: boolean | undefined) => {
  return !!isAnimatedProp;
};

export const RadarChart: React.FC<RadarChartProps> = ({
  data,
  maxValue,
  keys,
  margin,
  hasLegend,
  translateLegendX,
  translateLegendY,
  tooltipFn,
  onClickFn,
  isAnimated
}) => {
  return (
    <div className="radar-container-core" style={{ height: 500 }}>
      <ResponsiveRadar
        data={data}
        maxValue={maxValue}
        keys={keys}
        indexBy="label"
        valueFormat={">-.2f"}
        gridLabelOffset={36}
        dotSize={10}
        dotColor={{ theme: "background" }}
        dotBorderWidth={2}
        margin={getRadarMargin(margin)}
        colors={{ scheme: "nivo" }}
        borderWidth={2}
        borderColor={{ from: "color" }}
        curve="linearClosed"
        fillOpacity={0.25}
        blendMode="multiply"
        motionConfig={"wobbly"}
        isInteractive={true}
        animate={animatedRadar(isAnimated)}
        legends={getLegend(hasLegend, translateLegendX, translateLegendY)}
      />
    </div>
  );
};
