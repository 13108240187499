import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { BaseModel } from "../../../../../core/util/BaseModel";
import OrganisationsApi, {
  OrganisationsApi as IOrganisationsApi
} from "../../../../../services/api/v2/organisations/Organisations.api";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../services/api/v2/stakeholders/Stakeholders.api";
import { IModalContextModel } from "../../../../../core/modalZ/context/IModalContext";
import ModalContext from "../../../../../core/modalZ/context/ModalContext";

export class StakeholdersSettingsModel extends BaseModel {
  modalService: IModalContextModel;
  organisationsProvider: IOrganisationsApi;
  stakeholdersProvider: IStakeholdersApi;
  httpProgress: IProgressIndicatorModel;
  orgId: number;
  @observable searchQuery: string = "";
  @observable stakeholders: FP.Entities.IStakeholderSummary[];
  @observable selectedFileId: number = 0;
  @observable isLoading: boolean = true;
  @observable orgUsers: FP.Entities.IUser[] = [];
  @observable orgBusinessAreas: FP.Entities.IBusinessArea[] = [];
  @observable orgRoles: FP.Entities.IRole[] = [];
  /**
   *
   */
  constructor(organisationId: number) {
    super();
    makeObservable(this);
    this.orgId = organisationId;
    this.organisationsProvider = OrganisationsApi;
    this.stakeholdersProvider = StakeholdersApi;
    this.modalService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
  }

  onMount = async () => {
    Promise.all([
      await this.loadStakeholders(),
      await this.loadOrgUsers(),
      await this.loadOrgBusinessAreas(),
      await this.loadOrgRoles()
    ]);
    this.setIsLoading(false);
  };

  onUnmount = () => {};

  loadStakeholders = async () => {
    const res = await this.stakeholdersProvider.getGridData(this.orgId);

    if (!res?.payload || res.isError) return;

    this.setStakeholders(res.payload);
  };

  loadOrgUsers = async () => {
    const res = await this.organisationsProvider.getUsers(this.orgId);
    if (!res || res.isError) return;
    this.setOrgUsers(res.payload);
  };

  loadOrgBusinessAreas = async () => {
    const res = await this.organisationsProvider.getBusinessAreas(this.orgId);
    if (!res || res.isError) return;
    this.setOrgBusinessAreas(res.payload);
  };

  loadOrgRoles = async () => {
    const res = await this.organisationsProvider.getRoles(this.orgId);
    if (!res || res.isError) return;
    this.setOrgRoles(res.payload);
  };

  @action
  setOrgUsers = orgUsers => {
    this.orgUsers = orgUsers;
  };
  @action
  setOrgBusinessAreas = orgBusinessAreas => {
    this.orgBusinessAreas = orgBusinessAreas;
  };
  @action
  setOrgRoles = orgRoles => {
    this.orgRoles = orgRoles;
  };

  @action
  setStakeholders = (data: FP.Entities.IStakeholderSummary[]) => {
    this.stakeholders = data;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };
}
