import { ISdColDef, ISdFilterDef } from "../../../baseColumn/BaseColumnTypes";

export const TRAINING_PROGRESS_COLUMN_CONFIG: (field: string, list: any) => ISdColDef<any, any> = (field, list) => {
  return {
    cellEditorParams: {
      field: field,
      getValueLabel: (ee: any) => {
        if (!list) return "";

        const item = list.find(e => e.id === ee.id);

        if (!item) return "";
        return item.name;
      },
      options: !!list ? list : [],
      others: list,
      allowFreeText: true,
      isPerson: false,
      valueSelector: "name",
      filterFn: (items, query) => {
        const lowerQuery = query.toLowerCase();
        return items.filter((item: FP.Entities.ITrainingProgress) => {
          const lowerName = `${item.name}`.toLowerCase();
          return lowerName.indexOf(lowerQuery) > -1;
        });
      }
    }
  };
};
export const TRAINING_PROGRESS_FILTER_CONFIG: ISdFilterDef = {};
