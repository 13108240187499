import { ColDef, ICellEditorParams } from "ag-grid-community";
import moment from "moment";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { TrainingCourseField, YesNoChoices, defaultDateTimeString } from "../../../../../../enums";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { laterDateComparator } from "../../../../GridHelpers";
import {
  CommonColDefFieldNamesEnum,
  TrainingCourseColDefFieldNamesEnum,
  TrainingModuleColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  OWNER_NAME_COLUMN_CONFIG,
  OWNER_NAME_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/ownerName/OwnerNameColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import {
  DESCRIPTION_COLUMN_CONFIG,
  DESCRIPTION_FILTER_CONFIG
} from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import TrainingCoursesApi from "../../../../../../services/api/v2/trainingCourses/TrainingCourses.api";
import { TRAINING_COURSE_ACTIONS_SIDEBAR_MODAL_CONFIG } from "./modals/ActionLinkModal/ActionLinkModal_config";
import { DurationColumnBuilder } from "../../columns/commonColumns/DurationColumn/DurationColumn_builder";
import {
  DURATION_COLUMN_CONFIG,
  DURATION_FILTER_CONFIG
} from "../../columns/commonColumns/DurationColumn/DurationColumn_config";
import { yesNoTextMatcher } from "../../../../filters/TextMatcher";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { SimpleTextColumnBuilder } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_builder";
import { SIMPLE_TEXT_COLUMN_CONFIG } from "../../columns/commonColumns/SimpleTextColumn/SimpleTextColumn_config";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { TRAINING_COURSE_TAGS_SIDEBAR_MODAL_CONFIG } from "./modals/tagsSidebar/TrainingCourseTagsSidebarModal_config";
import { TRAINING_COURSE_NOTES_SIDEBAR_MODAL_CONFIG } from "./modals/noteCountSidebar/TrainingCourseNotesSidebarModal_config";
import { TRAINING_COURSE_PROCESSES_SIDEBAR_MODAL_CONFIG } from "./modals/ProcessLinkModal/ProcessLinkModal_config";
import {
  TRAINING_PROGRESS_COLUMN_CONFIG,
  TRAINING_PROGRESS_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/trainingProgress/TrainingProgressColumn_config";
import {
  TRAINING_TYPE_COLUMN_CONFIG,
  TRAINING_TYPE_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/trainingType/TrainingTypeColumn_config";
import { TRAINING_COURSE_STAKEHOLDER_OWNERS_SIDEBAR_MODAL_CONFIG } from "./modals/TrainingCourseStakeholdersLinkModal/TrainingCourseStakeholdersLinkModal_config";

export interface TrainingCoursesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewTrainingCourses: boolean;
  projectTeamMembers: any[];
  columns: string[];
  onFieldUpdate: () => void;
  trainingProgresses: any[]; //todo: add type
  trainingTypes: any[]; //todo: add type
}

export class TrainingCoursesGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: TrainingCoursesGridColumnBuilderProps;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  projectTeamMembers: any[];
  trainingProgresses: any[];
  trainingTypes: any[];
  projectId: number;
  onFieldUpdate: () => void;

  constructor(gridProps: TrainingCoursesGridColumnBuilderProps) {
    super(TrainingCoursesApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.projectTeamMembers = gridProps.projectTeamMembers;
    this.trainingProgresses = gridProps.trainingProgresses;
    this.trainingProgresses = gridProps.trainingProgresses;
    this.trainingTypes = gridProps.trainingTypes;
    this.onFieldUpdate = gridProps.onFieldUpdate;
    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [TrainingCourseColDefFieldNamesEnum.Code]: () => this.buildCodeColumn(),
      [TrainingCourseColDefFieldNamesEnum.TrainingProgress]: () => this.buildTrainingProgressColumn(),
      [TrainingCourseColDefFieldNamesEnum.TrainingType]: () => this.buildTrainingTypeColumn(),
      [TrainingModuleColDefFieldNamesEnum.Duration]: () => this.buildDurationColumn(),
      [CommonColDefFieldNamesEnum.StartDate]: () => this.buildStartDateColumn(),
      [CommonColDefFieldNamesEnum.EndDate]: () => this.buildEndDateColumn(),
      [CommonColDefFieldNamesEnum.Owner]: () => this.buildOwnerColumn(),
      [TrainingCourseColDefFieldNamesEnum.ProjectStakeholderOwners]: () =>
        this.buildPillsColumn(
          TrainingCourseColDefFieldNamesEnum.ProjectStakeholderOwners,
          I18n.t("grids.stakeholderOwners"),
          TRAINING_COURSE_STAKEHOLDER_OWNERS_SIDEBAR_MODAL_CONFIG,
          null,
          this.onFieldUpdate
        ).generateColumnOptions(),
      [TrainingCourseColDefFieldNamesEnum.TrainingModuleCount]: (header?: string) =>
        new NameColumnBuilder({
          field: TrainingCourseColDefFieldNamesEnum.TrainingModuleCount,
          headerName: header || I18n.t("grids.trainingModuleCount"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [TrainingCourseColDefFieldNamesEnum.IsSignedOff]: () => this.buildIsSignedOffColumn(),
      [CommonColDefFieldNamesEnum.Actions]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Actions,
          I18n.t("grids.actions"),
          TRAINING_COURSE_ACTIONS_SIDEBAR_MODAL_CONFIG,
          null,
          this.onFieldUpdate
        )
          .makeEditable(false)
          .makeReadOnly(false)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Processes]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Processes,
          I18n.t("grids.processesFromTrainingModule"),
          TRAINING_COURSE_PROCESSES_SIDEBAR_MODAL_CONFIG,
          null,
          this.onFieldUpdate
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.NoteCount]: () => this.buildNoteCountColumn(),
      [CommonColDefFieldNamesEnum.Tags]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Tags,
          I18n.t("grids.tags"),
          TRAINING_COURSE_TAGS_SIDEBAR_MODAL_CONFIG,
          null,
          this.onFieldUpdate
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .setFilterOptions(NAME_FILTER_CONFIG)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .setFilterOptions(DATE_FILTER_CONFIG)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e) {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(false)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      model.setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildCodeColumn = () => {
    let model = new SimpleTextColumnBuilder()
      .setColumnOptions(
        SIMPLE_TEXT_COLUMN_CONFIG({
          field: TrainingCourseColDefFieldNamesEnum.Code,
          headerName: I18n.t("grids.code")
        })
      )
      .makeSelectable(this.canEdit)
      .makeEditable(this.canEdit)
      .makeReadOnly(!this.canEdit)
      .setFilterOptions(BASE_FILTER_CONFIG);

    if (this.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateCode);
    }

    if (this.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildTrainingProgressColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({
          field: TrainingCourseColDefFieldNamesEnum.TrainingProgress,
          headerName: I18n.t("grids.trainingProgress")
        }),
        ...TRAINING_PROGRESS_COLUMN_CONFIG(TrainingCourseColDefFieldNamesEnum.TrainingProgress, this.trainingProgresses)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...TRAINING_PROGRESS_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[TrainingCourseColDefFieldNamesEnum.TrainingProgress]) return;
        return `${e.data[TrainingCourseColDefFieldNamesEnum.TrainingProgress] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.trainingProgressId = params.newValue.trainingProgressId;
        params.data[TrainingCourseColDefFieldNamesEnum.TrainingProgress] = `${params.newValue.name}`;

        this.updateTrainingProgress(params.data.id, params.newValue.trainingProgressId);
        return true;
      });
    return model.generateColumnOptions();
  };

  buildTrainingTypeColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({
          field: TrainingCourseColDefFieldNamesEnum.TrainingType,
          headerName: I18n.t("grids.trainingType")
        }),
        ...TRAINING_TYPE_COLUMN_CONFIG(TrainingCourseColDefFieldNamesEnum.TrainingType, this.trainingTypes)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...TRAINING_TYPE_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[TrainingCourseColDefFieldNamesEnum.TrainingType]) return;
        return `${e.data[TrainingCourseColDefFieldNamesEnum.TrainingType] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.trainingTypeId = params.newValue.id;
        params.data[TrainingCourseColDefFieldNamesEnum.TrainingType] = `${params.newValue.name}`;

        this.updateTrainingType(params.data.id, params.newValue.id);
        return true;
      });
    return model.generateColumnOptions();
  };

  buildStartDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.StartDate, headerName: I18n.t("grids.startDate") })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.StartDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.StartDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.StartDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => true)
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.StartDate,
          defaultDate: moment(),
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.StartDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }

            let startDate = moment(e);
            let endDate =
              params.data[CommonColDefFieldNamesEnum.EndDate] &&
              params.data[CommonColDefFieldNamesEnum.EndDate] !== defaultDateTimeString &&
              moment(params.data[CommonColDefFieldNamesEnum.EndDate]);

            if (!(startDate === endDate) && (!endDate || endDate > startDate)) {
              this.updateStartDate(params.data.id, startDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.StartDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildEndDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(DATE_COLUMN_CONFIG({ field: "endDate", headerName: I18n.t("grids.endDate") }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor("endDate", "")
      .setValueFormatter("endDate")
      .setValueGetterByFieldFn("endDate")
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => true)
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.EndDate,
          defaultDate: moment(),
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.EndDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            let endDate = moment(e);
            let startDate =
              params.data[CommonColDefFieldNamesEnum.StartDate] &&
              params.data[CommonColDefFieldNamesEnum.StartDate] !== defaultDateTimeString &&
              moment(params.data[CommonColDefFieldNamesEnum.StartDate]);

            if (
              !(endDate === startDate) &&
              (!startDate || laterDateComparator(endDate.toDate(), startDate.toDate()) === true)
            ) {
              this.updateEndDate(params.data.id, endDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.EndDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildOwnerColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.Owner, headerName: I18n.t("grids.owner") }),
        ...OWNER_NAME_COLUMN_CONFIG(CommonColDefFieldNamesEnum.Owner, this.projectTeamMembers)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...OWNER_NAME_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data[CommonColDefFieldNamesEnum.Owner]) return;
        return `${e.data[CommonColDefFieldNamesEnum.Owner] ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.ownerId = params.newValue.id;
        params.data[CommonColDefFieldNamesEnum.Owner] = `${params.newValue.firstName} ${params.newValue.lastName}`;

        this.updateOwnerName(params.data.id, params.newValue.id);
        return true;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildDurationColumn = () => {
    let model = new DurationColumnBuilder()
      .setColumnOptions({
        ...DURATION_COLUMN_CONFIG({
          field: TrainingModuleColDefFieldNamesEnum.Duration,
          headerName: I18n.t("grids.duration")
        })
      })
      .setFilterOptions({
        ...DURATION_FILTER_CONFIG
      })
      .makeSelectable(false)
      .makeEditable(false)
      .makeReadOnly(true)
      .withCellEditor()
      .setColumnOptions({
        valueFormatter: e => {
          const totalMinutes = Number(e.data[TrainingModuleColDefFieldNamesEnum.Duration]);
          if (isNaN(totalMinutes) || totalMinutes <= 0) {
            return "0 minutes";
          }
          if (totalMinutes < 60) {
            return `${totalMinutes} minute${totalMinutes === 1 ? "" : "s"}`;
          }
          if (totalMinutes < 1440) {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            let result = `${hours} hour${hours === 1 ? "" : "s"}`;
            if (minutes > 0) {
              result += ` ${minutes} minute${minutes === 1 ? "" : "s"}`;
            }
            return result;
          }
          const days = Math.floor(totalMinutes / 1440);
          const remainder = totalMinutes % 1440;
          const hours = Math.floor(remainder / 60);
          const minutes = remainder % 60;
          let result = `${days} day${days === 1 ? "" : "s"}`;
          if (hours > 0) {
            result += ` ${hours} hour${hours === 1 ? "" : "s"}`;
          }
          if (minutes > 0) {
            result += ` ${minutes} minute${minutes === 1 ? "" : "s"}`;
          }
          return result;
        }
      })
      .setValueGetter(e => {
        if (!e.data[TrainingModuleColDefFieldNamesEnum.Duration]) return;
        return `${e.data[TrainingModuleColDefFieldNamesEnum.Duration] ?? 0}`;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildIsSignedOffColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .makeEditable(this.gridProps.canEdit)
      .makeSelectable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setColumnOptions({
        field: TrainingCourseColDefFieldNamesEnum.IsSignedOff,
        headerName: I18n.t("grids.isSignedOff")
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;
        this.updateIsSignedOff(params.data.id, params.newValue.key);
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: TrainingCourseColDefFieldNamesEnum.IsSignedOff,
          getValueLabel: (ee: any) => {
            return ee ? YesNoChoices[1].label : YesNoChoices[0].label;
          },
          options: YesNoChoices ?? []
        }
      })
      .withCellEditor()
      .withCellRenderer(props => {
        let val = props.data[props.colDef.field];
        return val ? I18n.t("phrases.yes") : <span style={{ color: "#aaa" }}>{I18n.t("phrases.no")}</span>;
      })
      .setFilterOptions({
        filter: "agTextColumnFilter",
        filterParams: {
          textMatcher: yesNoTextMatcher
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable(true);
    }
    return model.generateColumnOptions();
  };

  buildNoteCountColumn = () => {
    let model = new NoteCountColumnBuilder()
      .setColumnOptions({ ...NOTE_COUNT_COLUMN_CONFIG(), field: CommonColDefFieldNamesEnum.NoteCount })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      const notesModal = (item: FP.Entities.ITrainingCourseSummary) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(TRAINING_COURSE_NOTES_SIDEBAR_MODAL_CONFIG(this.organisationId, this.projectId, item))
          .generateModal();

      model.setEditableOnDoubleClick(notesModal);
    }
    return model.generateColumnOptions();
  };

  //#region Update Field Methods

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(TrainingCourseField.description, entityId, text);
    await this.onFieldUpdate();
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(TrainingCourseField.name, entityId, text);
    await this.onFieldUpdate();
  };
  updateStartDate = async (entityId: number, date: Date) => {
    await this.updateDateField(TrainingCourseField.startDate, entityId, date);
    await this.onFieldUpdate();
  };
  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(TrainingCourseField.endDate, entityId, date);
    await this.onFieldUpdate();
  };
  updateTrainingProgress = async (entityId: number, data: any) => {
    await this.updateIdField(TrainingCourseField.trainingProgress, entityId, data);
    await this.onFieldUpdate();
  };
  updateTrainingType = async (entityId: number, data: any) => {
    await this.updateIdField(TrainingCourseField.trainingType, entityId, data);
    await this.onFieldUpdate();
  };
  updateOwnerName = async (entityId: number, data: any) => {
    await this.updateIdField(TrainingCourseField.owner, entityId, data);
    await this.onFieldUpdate();
  };
  updateCode = async (entityId: number, text: string) => {
    await this.updateTextField(TrainingCourseField.code, entityId, text);
    await this.onFieldUpdate();
  };
  updateIsSignedOff = async (entityId: number, text: string) => {
    await this.updateTextField(TrainingCourseField.isSignedOff, entityId, text);
    await this.onFieldUpdate();
  };
  //#endregion
}
