import { ColDef } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  ProgressIndicatorModel as IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";

import { EntityTypes } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import I18n from "../../../../../localization/I18n";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import ToasterService, { ToasterService as IToasterService } from "../../../../../toaster/ToasterService";
import { CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { AppGridState } from "../base/AppGrid_view";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { TrainingTypesGridColumnBuilder } from "./TrainingTypesGridView_columns";
import { GetTrainingTypesGridActions } from "./TrainingTypesGridView_actions";
import { SHOW_CONFIRM_CREATION_MODAL, SHOW_TRAINING_TYPE_DELETE_CONFIRM_MODAL } from "./TrainingTypesGridView_modals";
import TrainingTypesApi from "../../../../../../services/api/v2/trainingTypes/TrainingTypes.api";
export class TrainingTypesGridModel {
  organisationId: number;
  authUser: FP.Entities.IUser;
  canEditCoreData: boolean;
  trainingTypesGridColumnBuilder: TrainingTypesGridColumnBuilder;
  @observable selectedItems: number[] = [];
  @observable.ref gridActions: IGridUiAction[] = [];
  httpProgress: IProgressIndicatorModel;
  toasterService: IToasterService;
  microTrainingTypesForm: SingleFormModel;
  trainingTypesProvider = TrainingTypesApi;
  refreshDataHandler: any;
  @observable filterStoreAddon: FilterStoreAddon;
  @observable columnStateAddon: ColumnStateAddon;
  @observable textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  @observable gridColumns: ColDef<any, any>[];
  onFieldUpdate: () => void;

  constructor(organisationId: number, authUser: FP.Entities.IUser, refreshDataHandler: any, onFieldUpdate: () => void) {
    makeObservable(this);
    this.canEditCoreData = PermissionsContext.canContributeOrg(organisationId);
    this.organisationId = organisationId;
    this.authUser = authUser;
    this.toasterService = ToasterService;
    this.httpProgress = ProgressIndicatorModel;
    this.refreshDataHandler = refreshDataHandler;
    this.onFieldUpdate = onFieldUpdate;
    this.setMicroAddForm();
    this.generateAddons();
  }

  @action
  onMount = async () => {
    this.generateFn();
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  onUnmount = () => {};

  @action
  generateGridConfig = () => {
    this.trainingTypesGridColumnBuilder = new TrainingTypesGridColumnBuilder({
      canEdit: true,
      organisationId: this.organisationId,
      onFieldUpdate: this.onFieldUpdate,
      columns: [CommonColDefFieldNamesEnum.Selected, CommonColDefFieldNamesEnum.Name]
    });
    this.gridColumns = this.trainingTypesGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetTrainingTypesGridActions(this));
  };

  @action
  setMicroAddForm = () => {
    this.microTrainingTypesForm = new SingleFormModel();
    this.microTrainingTypesForm.formFields = getEntityNameMicroFormFields(
      this.createMicroTrainingType,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.trainingType") })
    );
  };

  @action
  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(null, EntityTypes.TRAINING_TYPES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: null,
      gridType: EntityTypes.TRAINING_TYPES,
      filterHasChangedFn: this.setIsFilterChanged
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: null,
      gridType: EntityTypes.TRAINING_TYPES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  @action
  createMicroTrainingType = async () => {
    if (!this.microTrainingTypesForm.isSaving) {
      let trainingTypeFormRes = await this.microTrainingTypesForm.submit();
      this.microTrainingTypesForm.isSaving = true;
      if (!trainingTypeFormRes) {
        this.microTrainingTypesForm.isSaving = false;
        return;
      }
      trainingTypeFormRes = {
        ...trainingTypeFormRes,
        organisationId: this.organisationId
      };

      this.httpProgress.showOverlay();
      let trainingTypeNameExists = await this.trainingTypesProvider.getByName(
        this.organisationId,
        trainingTypeFormRes.name
      );

      if (trainingTypeNameExists && !trainingTypeNameExists.isError && trainingTypeNameExists.payload.length) {
        this.httpProgress.hideOverlay();
        let confirmCreateTrainingType = await this.confirmCreateTrainingType(trainingTypeFormRes.name);
        if (!confirmCreateTrainingType) {
          this.microTrainingTypesForm.isSaving = false;
          return;
        }
        this.httpProgress.showOverlay();
      }
      const res = {
        ...trainingTypeFormRes
      };
      this.httpProgress.showOverlay();
      const result = await this.trainingTypesProvider.create(this.organisationId, res as FP.Entities.ITrainingType);

      this.httpProgress.hideOverlay();

      if (!result || result.isError) {
        this.microTrainingTypesForm.isSaving = false;
        return;
      }
      this.microTrainingTypesForm.isSaving = false;
      const trainingType = result.payload;
      await this.onFieldUpdate();
      this.microTrainingTypesForm.resetFields();
      this.toasterService
        .showSuccessToast()
        .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.trainingType") })}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      return trainingType;
    }
  };

  showTrainingTypeConfirmDeleteModal = () => {
    return SHOW_TRAINING_TYPE_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  confirmCreateTrainingType = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(name);
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.trainingTypesProvider
      .deleteRange(this.organisationId, null, itemIds)
      .then(e => this.refreshDataHandler());
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };
}
