import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { PositionedSpinner } from "../../components/ui/PositionedSpinner";
import { ConfigureAxios } from "../../services/api/v2/Http";
import { createAuth0Client } from "@auth0/auth0-spa-js";

export const SecureApp: React.FC<any> = ({ children }) => {
  const { isAuthenticated, error, isLoading, loginWithRedirect } = useAuth0();
  useEffect(() => {
    // If the user is not authenticated, initiate login
    if (!isAuthenticated && !isLoading && !error) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, error, loginWithRedirect]);
  if (error) {
    return `Oops, ${error}`;
  }
  if (isLoading || !isAuthenticated) {
    return <PositionedSpinner />;
  }
  return children;
};

export const HTTPProvider: React.FC<any> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const getAuthToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        return token;
      } catch (error) {
        console.error("Error fetching token:", error);
        throw error;
      }
    };
    // Configure Axios with the token getter
    ConfigureAxios(getAuthToken).then(e => {
      setIsLoading(false);
    });
  }, [getAccessTokenSilently]);

  if (isLoading) {
    return <PositionedSpinner />;
  }
  return children;
};

export const GetAuthToken = async () => {
  const auth0 = await createAuth0Client({
    domain: appConfig.auth0.domain,
    clientId: appConfig.auth0.clientId,
    authorizationParams: {
      audience: appConfig.auth0.audience, // Required if you are using an API
      scope: "openid profile email"
    }
  });

  const isAuthenticated = await auth0.isAuthenticated();

  if (!isAuthenticated) {
    await auth0.loginWithRedirect();
  }

  return await auth0.getTokenSilently();
};
