import { ColDef, GridApi, IRowNode } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes, GridTypes, TrainingModuleField } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import I18n from "../../../../../localization/I18n";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterAddon/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { GetTrainingModulesGridActions } from "./TrainingModulesGrid_actions";
import {
  LINK_TO_ACTIONS_MODAL_CONFIG,
  LINK_TO_PROCESSES_MODAL_CONFIG,
  SHOW_CONFIRM_CREATION_MODAL,
  SHOW_TRAINING_MODULE_DELETE_CONFIRM_MODAL
} from "./TrainingModulesGrid_modals";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { TrainingModulesGridColumnBuilder } from "./TrainingModulesGrid_columns";
import {
  CommonColDefFieldNamesEnum,
  TrainingModuleColDefFieldNamesEnum
} from "../../../enums/AgGridColDefFieldNameEnum";
import TrainingModulesApi, {
  TrainingModulesApi as ITrainingModulesApi
} from "../../../../../../services/api/v2/trainingModules/TrainingModules.api";
import TrainingProgressApi, {
  TrainingProgressApi as ITrainingProgressApi
} from "../../../../../../services/api/v2/trainingProgress/TrainingProgress.api";

export class TrainingModulesGridModel {
  organisationId: number;
  projectId: number;
  progressStatuses: FP.Generic.IKeyLabel[];
  trainingModulesProvider: ITrainingModulesApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  trainingProgressProvider: ITrainingProgressApi;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewTrainingModules: boolean;
  userCanEditTrainingModules: boolean;
  userCanViewActions: boolean;
  userCanViewProcesses: boolean;
  type: GridTypes = GridTypes.TRAINING_MODULES_GRID;
  trainingModulesGridColumnBuilder: TrainingModulesGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  @observable microTrainingModuleForm: SingleFormModel;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
  @observable projectTeamMembers: any[];
  @observable trainingProgress: any[];
  gridColumns: ColDef<any, any>[];
  urlSearchParams: URLSearchParams;
  gridToolbarType: AppGridToolbarType;
  gridType: EntityTypes | GridTypes;
  @observable isLoading: boolean = true;
  onFieldUpdate: () => void;
  refreshDataHandler?: any;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType,
    preSelectedItemIds: number[],
    gridType: EntityTypes | GridTypes,
    onFieldUpdate: () => void,
    refreshDataHandler?: any
  ) {
    makeObservable(this);
    this.trainingModulesProvider = TrainingModulesApi;
    this.trainingProgressProvider = TrainingProgressApi;
    this.onFieldUpdate = onFieldUpdate;
    this.refreshDataHandler = refreshDataHandler;
    this.userCanViewTrainingModules = PermissionsContext.canViewField(
      PermissionFields.PROJECTS,
      organisationId,
      projectId
    );
    this.userCanEditTrainingModules = PermissionsContext.canEditField(
      PermissionFields.PROJECTS,
      organisationId,
      projectId
    );
    this.userCanViewActions = PermissionsContext.canViewField(PermissionFields.ACTIONS, organisationId, projectId);
    this.userCanViewProcesses = PermissionsContext.canViewField(PermissionFields.PROCESSES, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.urlSearchParams = urlSearchParams;
    this.gridToolbarType = gridToolbarType;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.setMicroAddForm();
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.selectedItems = preSelectedItemIds;
    this.gridType = gridType;
    this.generateAddons();
  }

  loadInitData = async () => {
    if (this.userCanEditTrainingModules && this.gridToolbarType !== "link-modal") {
      await this.loadProjectTeamMembers();
      await this.loadTrainingProgress();
      this.generateFn();
    }
  };

  @action
  onMount = async () => {
    this.generateFn();
    await this.loadInitData();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  onUnmount = () => {};

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.TRAINING_MODULES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.TRAINING_MODULES,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });

    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.TRAINING_MODULES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    this.trainingModulesGridColumnBuilder = new TrainingModulesGridColumnBuilder({
      canEdit: this.userCanEditTrainingModules && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewTrainingModules: this.userCanViewTrainingModules,
      projectTeamMembers: this.projectTeamMembers,
      onFieldUpdate: this.onFieldUpdate,
      trainingProgress: this.trainingProgress,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        CommonColDefFieldNamesEnum.Name,
        CommonColDefFieldNamesEnum.Description,
        TrainingModuleColDefFieldNamesEnum.ModuleId,
        TrainingModuleColDefFieldNamesEnum.Duration,
        TrainingModuleColDefFieldNamesEnum.TrainingProgress,
        TrainingModuleColDefFieldNamesEnum.TrainingProgressPercentage,
        CommonColDefFieldNamesEnum.StartDate,
        CommonColDefFieldNamesEnum.EndDate,
        CommonColDefFieldNamesEnum.Owner,
        this.userCanViewActions && CommonColDefFieldNamesEnum.Actions,
        this.userCanViewProcesses && CommonColDefFieldNamesEnum.Processes,
        CommonColDefFieldNamesEnum.CreatedBy,
        CommonColDefFieldNamesEnum.CreatedAt,
        CommonColDefFieldNamesEnum.ModifiedBy,
        CommonColDefFieldNamesEnum.UpdatedAt
      ]
    });
    this.gridColumns = this.trainingModulesGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetTrainingModulesGridActions(this));
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  loadTrainingProgress = async () => {
    let res = await this.trainingProgressProvider.getGridData(this.organisationId);
    if (!res || res.isError) return;
    this.trainingProgress = res.payload;
  };

  @action
  setProjectTeamMembers = projectTeamMembers => {
    this.projectTeamMembers = projectTeamMembers;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  deleteFieldData = async (itemId: number, field: TrainingModuleField) => {
    const res = await this.trainingModulesProvider
      .deleteField(this.organisationId, this.projectId, itemId, field)
      .then(async e => {
        await this.onFieldUpdate();
        return e;
      });

    if (res?.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.trainingModulesProvider
      .deleteRange(this.organisationId, this.projectId, itemIds)
      .then(async e => await this.refreshDataHandler());

    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  setSelectedNodes = (gridApi: GridApi) => {
    const nodesToSelect: IRowNode[] = [];
    gridApi.forEachNode(node => {
      if (!!this.selectedItems?.includes(node.data.id)) {
        nodesToSelect.push(node);
      }
    });
    gridApi.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-training-modules-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  setMicroAddForm = () => {
    this.microTrainingModuleForm = new SingleFormModel();
    this.microTrainingModuleForm.formFields = getEntityNameMicroFormFields(
      this.createMicroTrainingModule,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.trainingModule") })
    );
  };

  @action
  createMicroTrainingModule = async () => {
    if (!this.microTrainingModuleForm.isSaving) {
      let microTrainingModuleFormRes = await this.microTrainingModuleForm.submit();
      this.microTrainingModuleForm.isSaving = true;
      if (!microTrainingModuleFormRes) {
        this.microTrainingModuleForm.isSaving = false;
        return;
      }

      microTrainingModuleFormRes = {
        ...microTrainingModuleFormRes,
        projectId: this.projectId,
        actions: [],
        processes: []
      };

      this.httpProgress.showOverlay();
      let trainingModuleNameExists = await this.trainingModulesProvider.getByProjectIdAndName(
        this.organisationId,
        this.projectId,
        microTrainingModuleFormRes.name
      );

      if (trainingModuleNameExists && !trainingModuleNameExists.isError && trainingModuleNameExists.payload.length) {
        this.httpProgress.hideOverlay();
        let confirmCreateTrainingModule = await this.confirmCreateTrainingModule(microTrainingModuleFormRes.name);
        if (!confirmCreateTrainingModule) {
          this.microTrainingModuleForm.isSaving = false;
          return;
        }
        this.httpProgress.showOverlay();
      }

      const res = {
        ...microTrainingModuleFormRes
      };
      this.httpProgress.showOverlay();
      const result = await this.trainingModulesProvider
        .create(this.organisationId, this.projectId, res as FP.Entities.ITrainingModule)
        .then(e => this.refreshDataHandler());

      this.httpProgress.hideOverlay();

      if (!result || result.isError) {
        this.microTrainingModuleForm.isSaving = false;
        return;
      }
      this.microTrainingModuleForm.isSaving = false;

      const trainingModule = result.payload;

      this.microTrainingModuleForm.resetFields();
      this.toasterService
        .showSuccessToast()
        .setContent(
          <span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.trainingModule") })}</span>
        )
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
      return trainingModule;
    }
  };

  //#region Grid Action Toolbar Modals Methods [#11111190]
  confirmCreateTrainingModule = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(name);
  };

  showTrainingModuleConfirmDeleteModal = () => {
    return SHOW_TRAINING_MODULE_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  assignActionsToImpacts = async actionIds => {
    await this.trainingModulesProvider
      .addBulkActionsToTrainingModules(this.organisationId, this.projectId, this.selectedItems, actionIds)
      .then(e => this.onFieldUpdate());

    this.modalService.hide();
  };

  assignProcessesToImpacts = async projectProcessIds => {
    await this.trainingModulesProvider
      .addBulkProjectProcessesToTrainingModules(
        this.organisationId,
        this.projectId,
        this.selectedItems,
        projectProcessIds
      )
      .then(e => this.onFieldUpdate());

    this.modalService.hide();
  };

  //#endregion

  //#region Grid Link Action Modal Methods [#11122290]
  showLinkToActions = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_ACTIONS_MODAL_CONFIG(this.assignActionsToImpacts))
      .generateModal();
  };
  showLinkToProcesses = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_PROCESSES_MODAL_CONFIG(this.selectedItems, this.assignProcessesToImpacts))
      .generateModal();
  };

  //#endregion

  //#region Grid Link Action Methods [#11133390]
  //#endregion
}
