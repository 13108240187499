import I18n from "../../../../../../../localization/I18n";
import { GridSideModalTitle } from "../../../../utils/GridModalUtils";
import { TrainingCourseNotesSidebar } from "./TrainingCourseNotesSidebar";

export const TRAINING_COURSE_NOTES_SIDEBAR_MODAL_CONFIG = (
  organisationId: number,
  projectId: number,
  item: FP.Entities.ITrainingCourseSummary
) => {
  return {
    showClose: true,
    title: <GridSideModalTitle name={`${item.name}`} field={I18n.t("phrases.notes")} />,
    content: <TrainingCourseNotesSidebar projectId={projectId} itemId={item.id} />
  };
};
